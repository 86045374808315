<template>
  <div>
    <b-row class="mt-2">
      <b-button class="btn btn-primary add-button mb-1"  variant="primary" @click="openNewModal">{{ $t('Add CPA') }}</b-button>

        <b-table
            v-if="affiliate.affiliateCpas!=null"
            ref="cpaTable"
            striped
            hover
            responsive
            v-bind:items="affiliate.affiliateCpas"
            v-bind:fields="fields"
        >
          <template #cell(creationTime)="data">
            {{
              $moment(data.value)
                  .format('DD/MM/YYYY HH:mm:ss')
            }}
          </template>

          <template #cell(cpa)="data">
            {{ convertToEURO(data.value) }}
          </template>
          <template #cell(creationTime)="data">
            {{ data.value }}
          </template>

          <template #cell(actions)="row">
            <b-button size="sm" class="btn btn-danger"
                      @click="deleteOpen(row.item, row.index, $event.target)">
              {{ $t('Delete') }}
            </b-button>
          </template>

        </b-table>
    </b-row>
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
      <b-button class="d-inline-block" size="sm" variant="outline-warning">{{ $t('operations.reset') }}</b-button>&nbsp;
      <b-button class="d-inline-block" size="sm" variant="outline-success" @click="modifyAffiliate">{{ $t('operations.save') }}
      </b-button>
    </div>
    <b-modal :id="deleteModal.id" :title="$t('Confirmation')" @ok="deleteCpaById(deleteModal.cpaId)"
             ok-variant="danger" :cancel-title="$t('operations.cancel')" @hide="resetInfoModal">
      <p class="my-4">{{ $t('areyousureyouwanttodeletethisexpenses') }}</p>
    </b-modal>
    <b-modal :id="newModal.id" :title="$t('Create new cpa entry')" :ok-title="$t('operations.add')" size="sm" @ok="createCpa(newModal.cpa)"
             ok-variant="success" cancel-variant="danger" :cancel-title="$t('operations.cancel')" @hide="resetInfoModal">

      <b-col cols="12" xl="12">
        <label>{{ $t('columns.cpa') }}</label>
        <b-form-input v-model="newCpa.cpa" type="number"/>
      </b-col>

      <b-col cols="12" xl="12">
        <label>{{ $t('columns.crg') }}</label>
        <b-form-input v-model="newCpa.crg" type="number"/>
      </b-col>

      <b-col cols="12" xl="12">
        <label>{{ $t('columns.crgWeek') }}</label>
        <b-form-input v-model="newCpa.crgWeek" type="number"/>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import Affiliate from '@/model/affiliate/Affiliate';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import { mapActions, mapGetters } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox';
import CalendarPicker from '@/components/widget/picker/calendarPicker';
import mixinBase from '@/components/mixin/mixinBase';

export default {
  name: 'AffiliateEditCpa',
  components: {
    CalendarPicker,
    CountriesSelectBox,
    BusinessUnitSelectBox,
    OrganizationSelectBox
  },
  mixins: [mixinPermissions, mixinNotifications, mixinBase],
  data: () => ({
    affiliate: Affiliate(),
    affiliateCpa: {},
    newCpa:{},
    fields: [],
    deleteModal: {
      id: 'delete-modal',
      title: 'Delete Cpa entry',
      content: ''
    },
    newModal: {
      id: 'new-modal',
      title: 'Create new Cpa entry',
      content: ''
    },
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newAffiliate: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.affiliate = Object.assign(Affiliate(), this.value);
      },
      immediate: true
    }
  },
  created() {
    this.setFields();
  },
  computed: {
    ...mapGetters('affiliate', [''])
  },
  methods: {
    ...mapActions('affiliate', ['updateAffiliate', 'deleteCpa','createCpaAffiliate']),

    setFields() {
      let $this = this;
      this.fields = [
        {
          key: 'creationTime',
          label: $this.$t('columns.creationTime'),
          sortable: true
        },

        {
          key: 'cpa',
          label: $this.$t('columns.cpa'),
          sortable: true
        },

        {
          key: 'crg',
          label: $this.$t('columns.crg'),
          sortable: true
        },
        {
          key: 'crgWeek',
          label: $this.$t('columns.crgWeek'),
          sortable: true
        },

        {
          key: 'actions',
          label: $this.$t('Actions'),
          sortable: true
        },
      ];
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.deleteModal.id)
      this.$root.$emit('bv::hide::modal', this.newModal.id)
    },
    async deleteCpaById(cpaId) {
      let $this = this;
      await this.deleteCpa({
        id: this.affiliate.id,
        cpaId: cpaId
      })
          .catch(err => {
            this.showErrorNotification(this.$t('globalMessages.fetchDataError', { system: this.$t('menu.affiliates') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.affiliates') }));
      this.$emit('input', this.affiliate);

    },
    async createCpa() {
      let $this = this;
      await this.createCpaAffiliate({
        id: this.affiliate.id,
        cpa: this.newCpa
      })
          .catch(err => {
            this.showErrorNotification(this.$t('globalMessages.fetchDataError', { system: this.$t('menu.affiliates') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.affiliates') }));
      this.$emit('input', this.affiliate);

    },
    async modifyAffiliate() {
      let $this = this;

      await this.updateAffiliate({
        affiliate: this.affiliate,
        id: this.affiliate.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.affiliates') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.affiliates') }));
      this.$emit('input', this.affiliate);
      this.$emit('close');

    },
    deleteOpen(item, index, button) {
      this.deleteModal.title = `Row index: ${index}`
      this.deleteModal.cpaId = item.id;
      this.$root.$emit('bv::show::modal', this.deleteModal.id, button)
    },
    openNewModal() {
      this.newCpa={}
      this.$root.$emit('bv::show::modal', this.newModal.id)
    },
    async save() {
      if (this.affiliate.id == null) {
        await this.addAffiliate();
      } else {
        await this.modifyAffiliate();
      }
    }
  }
};
</script>

<style scoped>
.table-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.add-button {
  margin-left: 1rem;
}
</style>
