<template>
  <div>
    <b-row>

      <b-col cols="6" xl="6">
        <label>{{ $t('columns.company') }}</label>
        <b-form-input v-model="affiliate.company"/>
      </b-col>


      <b-col cols="6" xl="6">
        <label>{{ $t('columns.email') }}</label>
        <b-input-group>
          <b-form-input v-model="affiliate.email"/>
<!--          <b-input-group-append>
            <b-button variant="primary" @click="openEmailEditor"
                      v-b-tooltip :title="$t('operations.sendMail')"
            >
              <i class="fa-solid fa-envelope"></i>
            </b-button>
          </b-input-group-append>-->
        </b-input-group>
      </b-col>
    </b-row>
    <div class="mt-1" v-if="affiliate.id!=null">
      <affiliate-token-table v-bind:affiliateId="affiliate.id"/>
    </div>
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
      <b-button block class="d-inline-block" variant="primary" @click="save">{{ $t('operations.save') }}
      </b-button>
    </div>
    <email-editor-modal @emailTemplateSaved="sendEmail" ref="emailEditorModal" :receiver="affiliate"
    ></email-editor-modal>

  </div>
</template>

<script>
import Affiliate from '@/model/affiliate/Affiliate';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import { mapActions, mapGetters } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox';
import CalendarPicker from '@/components/widget/picker/calendarPicker';
import mixinBase from '@/components/mixin/mixinBase';
import EmailEditorModal from '@/components/partial/mailTemplates/EmailEditorModal';
import AffiliateTokenTable from '@/components/tables/AffiliateTokenTable.vue';

export default {
  name: 'AffiliateEditDetails',
  components: {
    AffiliateTokenTable,
    EmailEditorModal,
    CalendarPicker,
    CountriesSelectBox,
    BusinessUnitSelectBox,
    OrganizationSelectBox
  },
  mixins: [mixinPermissions, mixinNotifications, mixinBase],
  data: () => ({
    affiliate: Affiliate(),
    tokens: [],
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newAffiliate: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.affiliate = Object.assign(Affiliate(), this.value);
      },
      immediate: true
    }
  },
  created() {
  },
  computed: {
    ...mapGetters('affiliate', [''])
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateTokens', 'updateAffiliate', 'createAffiliate']),
    openEmailEditor() {
      // Show the email editor modal
      this.$refs.emailEditorModal.showModal = true;
    },
    sendEmail() {
      this.$refs.emailEditorModal.showModal = false;
    },
    updateAffiliateTokens() {
      this.tokens = [];
      this.getAffiliateTokens({ id: this.affiliate.id })
          .then(response => {
            this.tokens = response.data;
          });
    },
    async addAffiliate() {
      let $this = this;
      try {
        let affiliate = await this.createAffiliate({
          affiliate: this.affiliate,
        });
        this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.affiliates') }));
        this.$emit('input', affiliate);
        this.$emit('updated', affiliate.id);
        this.refreshTables();
      } catch (err) {
        $this.showServerErrorNotification('addDataError', 'affiliates', err);
      }
    }
    ,

    async modifyAffiliate() {
      let $this = this;
      try {
        let affiliate = await this.updateAffiliate({
          affiliate: this.affiliate,
          id: this.affiliate.id

        });

        this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.affiliates') }));
        this.$emit('input', affiliate);
        this.$emit('updated', affiliate.id);
        this.refreshTables();
      } catch (err) {
        $this.showServerErrorNotification('addDataError', 'affiliates', err);
      }

    },

    async save() {
      if (this.affiliate.id == null) {
        await this.addAffiliate();
      } else {
        await this.modifyAffiliate();
      }
    }
  }
};
</script>

<style scoped>
.table-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.add-button {
  margin-left: 1rem;
}
</style>
