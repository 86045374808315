export default function (other) {
  other = other || {}
  return {
    id: other.id,
    affiliateId: other.affiliateId,
    representativeId: other.representativeId,
    campaignId: other.campaignId,
    orgId: other.orgId,
    businessUnitId: other.businessUnitId,
    subAffiliateId: other.subAffiliateId,
    tag: other.tag,
    active: other.active,
    creationTime: other.creationTime,
  }
}
