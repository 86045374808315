<template>
  <b-modal no-fade v-model="dialog.show" size="xl" :id="`modal-affiliate-${uniqueId}`"
           :hide-footer="true" centered @close="closeModal"
           no-close-on-backdrop
  >
    <template #modal-title>
     <span v-if="loading !== true && error == null && affiliate != null">
       {{ affiliate.firstName }}  {{ affiliate.lastName }}
     </span>
    </template>
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <b-tabs  v-model="dialog.tab" content-class="" align="center">
          <b-tab :title="$t('tabs.affiliates.details')" active>
            <affiliate-edit-details v-bind:new-affiliate="isNewAffiliate" @submitted="onSubmitted" v-model="affiliate"/>
          </b-tab>

          <b-tab v-if="!isNewAffiliate" :title="$t('tabs.affiliates.priceManagement')">
            <affiliate-edit-cpa  v-model="affiliate"/>
          </b-tab>

          <b-tab v-if="!isNewAffiliate" :title="$t('tabs.affiliates.timeManagement')">
            <affiliate-edit-times  v-model="affiliate.openingTime"/>
          </b-tab>
        </b-tabs>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%"
           v-else-if="loading !== true && error != null"
      >
        <b-alert show variant="danger">
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{ $t('operations.loading') }}</b-spinner>
      </div>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';
import AffiliateEditDetails from '@/components/partial/affiliates/AffiliateEditDetails';
import Affiliate from '@/model/affiliate/Affiliate';
import AffiliateEditCpa from '@/components/partial/affiliates/AffiliateEditCpa';
import AffiliateEditTimes from '@/components/partial/affiliates/AffiliateEditTimes';

export default {
  name: 'affiliateModal',
  components: { AffiliateEditDetails , AffiliateEditCpa, AffiliateEditTimes},
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
      tab: 0,

    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewAffiliate: false,
    affiliate: {},
  }),
  watch: {
    queryParams: {
      handler() {
        if (this.queryParams.affiliateId == null) {
          this.closeModal();
          return;
        }
        if (this.queryParams.affiliateId !== this.id) {
          this.showModal(this.queryParams.affiliateId);
        }
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid;
    if (this.queryParams.affiliateId != null) {
      this.showModal(this.queryParams.affiliateId);
    }
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateById']),
    showModal(id) {
      this.id = id;
      this.loading = true;
      this.dialog.show = true;
      this.dialog.tab = 0;
      this.isNewAffiliate = this.id == null || this.id === 0;
      if (!this.isNewAffiliate) {
        this.refreshData();
      } else {
        this.affiliate = Affiliate();
        this.error = null;
        this.loading = false;
      }
      this.pushState({
        affiliateId: id,
        tab: 0,
      });
    },
    closeModal() {
      this.dialog.show = false;
      this.affiliate = {};
      this.onDialogClose();
    },
    onDialogClose() {
      this.id = null;
      this.pushState({
        affiliateId: null,
        tab: null,
      });
    },
    refreshData() {
      let $this = this;
      this.getAffiliateById(this.id)
          .then(affiliate => {
            $this.affiliate = affiliate;
            $this.error = null;
            $this.loading = false;
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      console.log('submitted', this.selected);
      this.refreshData()
      this.dialog.tab = 0;
    }
  }
};
</script>

<style scoped>

</style>
