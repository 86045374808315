export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    company: other.company || null,
    orgId: other.orgId || null,
    branchId: other.branchId || null,
    /*  firstName: other.firstName || null,
     lastName: other.lastName || null,
     login: other.login || null,
     phone: other.phone || null, */
    email: other.email || null,
    /*    alias: other.alias || null,
       code: other.code || null, */
    active: other.active || true,
    /*   website: other.website || null,
      group: other.group || null,
      skype: other.skype || null,
      alertEmail: other.alertEmail || null, */
    hideFtd: other.hideFtd || null,
    affiliateCpas: other.affiliateCpas || null,
    token: other.token || null,
    creationTime: other.creationTime || null,
  }
}
