<template>
  <div>
    <div v-for="day in weekDays" v-bind:key="day">
      <b-row no-gutters v-if="Array.isArray(affiliateDays[day])">
        <div class="col-1 mt-2">
          <b-form-checkbox v-model="affiliateDays[day][0]['open']" class="md-primary text-capitalize">
            {{ $t(day.toLowerCase()) }}
          </b-form-checkbox>
        </div>
        <div class="col mb-2 mt-1">
          <vue-ctk-date-time-picker :id="`open-time-picker-${day}`" onlyTime
                                    noClearButton
                                    :label="$t('Open Time')"
                                    v-model="affiliateDays[day][0]['openHour']"
                                    format="HH:mm"
                                    formatted="HH:mm"
                                    :disabled="affiliateDays[day][0]['open'] === false"
          />

        </div>
        <div class="col mt-1">
          <vue-ctk-date-time-picker :id="`close-time-picker-${day}`" onlyTime
                                    noClearButton
                                    :label="$t('Close Time')"
                                    v-model="affiliateDays[day][0]['closeHour']"
                                    format="HH:mm"
                                    formatted="HH:mm"
                                    :disabled="affiliateDays[day][0]['open'] === false"
          />
        </div>
      </b-row>
      <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
        <b-button class="d-inline-block" size="sm" variant="outline-warning">{{ $t('operations.reset') }}</b-button>&nbsp;
        <b-button class="d-inline-block" size="sm" variant="outline-success" @click="modifyAffiliate">{{ $t('operations.save') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Affiliate from '@/model/affiliate/Affiliate';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import { mapActions, mapGetters } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import CalendarPicker from '@/components/widget/picker/calendarPicker';
import mixinBase from '@/components/mixin/mixinBase';

export default {
  name: 'AffiliateEditTimes',
  components: {
    CalendarPicker,

  },
  mixins: [mixinPermissions, mixinNotifications, mixinBase],
  data: () => ({}),
  props: {
    value: {
      required: true,
      default: () => ({})
    }
  },
  watch: {
    value: {
      handler() {
        this.affiliate = Object.assign(Affiliate(), this.value);
      },
      immediate: true
    }
  },
  created() {
  },
  computed: {
    ...mapGetters('affiliate', ['']),
    weekDays: () => ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
    affiliateDays: {
      get() {
        if (this.value == null) {
          return {};
        }
        return { ...this.value };
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    ...mapActions('affiliate', ['updateAffiliate']),
    checkData() {
      let affiliateDays = { ...this.affiliateDays };
      let updated = false;
      this.weekDays.forEach(day => {
        if (Array.isArray(affiliateDays[day])) {
          return;
        }
        affiliateDays[day] = [{
          open: false,
          openHour: '07:00',
          closeHour: '19:00'
        }];
        updated = true;
      });
      if (updated) {
        this.affiliateDays = affiliateDays;
      }
    },
    async modifyAffiliate() {
      let $this = this;

      await this.updateAffiliate({
        affiliate: this.affiliate,
        id: this.affiliate.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.affiliates') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.affiliates') }));
      this.$emit('input', this.affiliate);
      this.$emit('close');

    },
  }
};
</script>

<style scoped>
.table-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.add-button {
  margin-left: 1rem;
}
</style>
