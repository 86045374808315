<template>
  <div>
    <div class="text-right" style="margin-bottom: 5px">
      <b-button size="sm" variant="outline-success" @click="addNewToken">
        <i class="fa-solid fa-plus"></i>
        {{$t('operations.add')}}
      </b-button>
    </div>
    <b-table-simple hover small caption-top responsive >
      <b-thead>
        <b-tr>
          <b-th>{{$t('columns.token')}}</b-th>
          <b-th>{{$t('columns.creationTime')}}</b-th>
          <b-th>{{$t('columns.businessUnitId')}}</b-th>
          <b-th>{{$t('columns.representative')}}</b-th>
          <b-th>{{$t('columns.campaign')}}</b-th>
          <b-th>{{$t('columns.tag')}}</b-th>
          <b-th>{{$t('columns.active')}}</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="!loading">
        <b-tr v-for="token in tokens" v-bind:key="token.id">
          <b-td>{{token.id}}</b-td>
          <b-td>{{token.creationTime | momentDate}}</b-td>
          <b-td>{{getOrganizationNameById(token.orgId)}} {{getBusinessUnitNameById(token.businessUnitId)}}</b-td>
          <b-td>{{getUserFullName(token.representativeId)}}</b-td>
          <b-td>{{token.campaignId}}</b-td>
          <b-td>{{token.tag}}</b-td>
          <b-td class="text-capitalize" :variant="token.active ? 'success': null">{{token.active ? $t('operations.yes') : $t('operations.no')}}</b-td>
          <b-td>
            <b-button variant="outline-primary" size="sm" @click="()=>copyText(token)"><i class="fa-solid fa-copy"></i></b-button>
            <b-button variant="outline-primary" class="ml-1" size="sm" @click="()=>toggleEnableToken(token)"><i class="fa-solid" v-bind:class="{'fa-plug':token.active, 'fa-plug-circle-minus':!token.active}"></i></b-button>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody v-else>
        <b-tr>
          <b-td colspan="8" class="text-center">
            <b-spinner>{{ $t('operations.loading') }}</b-spinner>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-modal ref="newTokenModal" :title="$t('newToken')" centered size="lg" hide-footer>
      <b-form v-if="newToken">
        <b-row>
          <b-col cols="6" v-if="hasManyOrganizations">
            <b-form-group label-for="orgId">
              <label>{{$t('columns.orgId')}}</label>
              <organization-select-box class="small" v-model="newToken.orgId"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="businessUnitId">
              <label>{{$t('columns.businessUnitId')}}</label>
              <business-unit-select-box class="small" v-model="newToken.businessUnitId"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="representative">
              <label>{{$t('columns.representative')}}</label>
              <user-select-box class="small" v-model="newToken.representativeId"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group  label-for="campaign">
              <label>{{$t('columns.campaign')}}</label>
              <b-form-input size="sm" v-model="newToken.campaignId"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="tag">
              <label>{{$t('columns.tag')}}</label>
              <b-form-input size="sm" v-model="newToken.tag"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="active">
              <b-form-checkbox style="margin-top: 28px" v-model="newToken.active">{{$t('columns.active')}}</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button size="sm" block variant="outline-success" @click="saveToken">{{$t('operations.save')}}</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AffiliateToken from '@/model/affiliate/AffiliateToken'
import userSelectBox from '@/components/widget/select/UserSelectBox.vue'
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox.vue'
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox.vue'
export default {
  name: 'AffiliateTokenTable',
  props: ['affiliateId'],
  components: { BusinessUnitSelectBox, OrganizationSelectBox, userSelectBox },
  watch: {
    affiliateId: {
      handler() {
        this.updateTokens()
      },
      immediate: true
    }
  },
  data: () => ({
    tokens: [],
    loading: true,
    newToken: null,
  }),
  computed:{
    ...mapGetters('data', ['hasManyOrganizations', 'getOrganizationNameById', 'getOrganizationCrmId', 'getBusinessUnitNameById', 'getUserFullName']),
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateTokens', 'createAffiliateToken', 'updateAffiliateToken']),
    updateTokens() {
      let $this = this;
      this.tokens = []
      this.loading = true;
      this.getAffiliateTokens(this.affiliateId)
          .then((response) => $this.tokens = response || [])
          .finally(() => $this.loading = false)
    },
    addNewToken() {
      let $this = this;
      this.newToken = AffiliateToken({affiliateId:$this.affiliateId,active:true})
      this.$refs.newTokenModal.show()
    },
    async copyText(token){
      try {
        let affiliateApiText = "Dear Affiliate, \n\n" +
            "Please use the following token to access the affiliate portal: \n\n" +
            token.id + "\n\n" +
            "Use this url as api base: \n\n" +
            this.getOrganizationCrmId(token.orgId) + "\n\n" +
            "Documentation can be found at: \n" +
            this.getOrganizationCrmId(token.orgId) + "api/public/swagger-ui/index.html?urls.primaryName=affiliate\n" +
            this.getOrganizationCrmId(token.orgId) + "api/public/api-docs/affiliate\n\n" +
            "Best regards, \n" +
            "Affiliate Management Team";
        await navigator.clipboard.writeText(affiliateApiText);
        console.log('Content copied to clipboard');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    saveToken() {
      let $this = this;
      this.loading = true;
      this.createAffiliateToken({ ...this.newToken })
          .then(() => {
            $this.updateTokens()
            $this.$refs.newTokenModal.hide()
          })
          .finally(() => $this.loading = false)
    },
    toggleEnableToken(token){
      token.active = !token.active;
      this.loading = true;
      this.updateAffiliateToken({ ...token })
          .then(() => {
            this.updateTokens()
          })
          .finally(() => this.loading = false)

    }
  }
}
</script>

<style scoped>

</style>
